import {createRouter, createWebHashHistory} from 'vue-router';

const routes = [
    {
        name: 'notFound',
        path: '/:path(.*)+',
        redirect: {
            name: 'any',
        },
    },
    {
        name: 'login',
        path: '/login',
        component: () => import('./view/login'),
        meta: {
            title: '我的技术学习',
        },
    },

    {
        name: 'any',
        path: '/any',
        component: () => import('./view/any'),
        meta: {
            title: '矮泥鸭首页',
        },
    },

    {
        name: 'reg',
        path: '/reg',
        component: () => import('./view/reg'),
        meta: {
            title: '注册',
        },
    }
    ,

    {
        name: 'member',
        path: '/member',
        component: () => import('./view/member'),
        meta: {
            title: '用户中心',
        }
    },
    {
        name: 'milk',
        path: '/milk',
        component: () => import('./view/milk'),
        meta: {
            title: '崽崽日常',
        },

    },
    {
        name: 'valid',
        path: '/valid',
        component: () => import('./view/valid'),
        meta: {
            title: '有期列表',
        },

    },
    {
        name: 'validAdd',
        path: '/validAdd',
        component: () => import('./view/validAdd'),
        meta: {
            title: '新增有期',
        },

    }


];

const router = createRouter({
    routes,
    history: createWebHashHistory(),
});

router.beforeEach((to, from, next) => {
    const title = to.meta && to.meta.title;
    if (title) {
        document.title = title;
    }
    next();
});

export {router};
